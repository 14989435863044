.logo{
    width: 300px;
    height: auto;
}



    .result-box{
        /*border-style: solid;/*
        border-width: thin;*/
        min-width: 5em;
        text-align: center;
    }

    .accord-label{
        font-size: 13px !important; 
        font-weight: bold;

    }

    .accord-sub-label {
        font-size: 12px !important; 
       

    }


    .accord-sub-sub-label {
        font-size: 14px !important; 
        padding-left: 15%;
    }

    .cont {
        display: flex;
        justify-content: center;
        }

    .results-card{
        max-width: 50em;
        padding-bottom: 3em;
    }

    .card-center{
        width:90%; 
        display:block;
        margin: 0 auto;
    }

    
    .link-popup{
        border-style: none;
        background-color: transparent;
        color: blue;
        padding-left: 0px;
    }

    .outline-box{
        border-style: solid;
        border-width: thin;
    }

    .popup-header{
        width: 100%;
        height: 40px;
        background-color: #ffffff00;
    }
   



        
